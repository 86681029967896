/*****************************************
* BASE
******************************************/
/* Libs
******************************************/
@import 'normalize';
@import 'lib/icons';

/* Variables
******************************************/
// Colors
$color_black: #232222;
$color_grey-dark: #3d3935;
$color_grey: #767676;
$color_grey-light: #f9f9f9;
$color_white: #fff;
$color_orange: #d97f00;
$color_pink: #ae2573;

// Margins
$margin_x: 170px;
$margin_m: 60px;
$margin_s: 40px;

/* Mixins
******************************************/
// States
@mixin state {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

// Fonts
@mixin fs($size, $color: false) {
  @if $color {
    color: $color;
  }
  @if $size == h1 {
    font-size: 32px;
    @media (min-width: 768px) {
      font-size: 42px;
    }
  }

  @else if $size == h3 {
    font-size: 20px;
    line-height: 30px;
    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  @else if $size == btn {
    font-size: 18px;
    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  @else {
    font-size: 16px;
    line-height: 30px;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}


// Transitions
@mixin transitionColor($color: $color_pink, $time: 0.25s) {
  @include state {
    color: $color;
  }
  transition: color $time ease-out;
}

@mixin transitionOpacity($time: 0.25s) {
  transition: opacity $time ease-out;
}


/* Reset
******************************************/
body {
  padding-top: 80px;
  color: $color_grey-dark;
  font-size: 20px;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}
main {
  min-height: calc(100vh - 505px);
}
button {
  border: none;
  background: transparent;
  cursor: pointer;
}
h1,
h2,
h3,
h4,
p,
ul {
  margin: 0;
  margin-bottom: $margin_s;
}
li {
  margin-bottom: $margin_s / 2;
}
p {
  @include fs(p);
}
p:last-child {
  margin-bottom: 0;
}
a {
  @include state {
    color: $color_pink;
  }
  color: inherit;
}
img {
  max-width: 100%;
  height: 100%;
}
:focus {
  outline-color: $color_pink;
}
::selection {
  background: transparentize($color_pink, 0.8);
}

/* Transitions
******************************************/
// Fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
