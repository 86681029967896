/*****************************************
* MODULES
******************************************/
/* Usful
******************************************/
.bg-container {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.text-center {
  text-align: center;
}
.row-trick {
  position: relative;
  flex-direction: column;
}
.unscroll {
  overflow: hidden;
}
.sticky-container {
  position: relative;
  .sticky-element {
    position: static;
    top: 150px;
    @media (min-width: 768px) {
      position: sticky;
    }
  }
}
.mr-auto {
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}
.space {
  margin-bottom: $margin_x;
}
.mb-kill > .row {
  margin-bottom: 0!important;
}

/* Typo
******************************************/
// Base
.h1,
.h2,
.h3 {
  font-weight: bold;
  font-family: 'Poppins', sans-serif;

  // Lines
  &.line {
    position: relative;
    &:before {
      position: absolute;
      top: calc(50% - 2px);
      left: -100px;
      display: block;
      display: none;
      width: 65px;
      height: 4px;
      content: '';
      @media (min-width: 768px) {
        display: block;
      }
    }
    &.l-orange:before {
      background: $color_orange;
    }
    &.l-white:before {
      background: $color_white;
    }
  }
}

// Spec
.h1 {
  @include fs(h1);
}
.h2 {
  @include fs(h1);
}
.h3 {
  @include fs(h3);
}
/* Form
******************************************/
.vfbp-form {
  margin-right: -15px;
  margin-left: -15px;
  .vfb-col-6 {
    padding-bottom: 25px;
    width: 100%;
    @media (min-width: 992px) {
      float: left;
      width: 50%;
    }
  }
  .vfb-fieldType-submit {
    padding-top: 25px;
    text-align: center;
  }
  .vfb-fieldType-instructions {
    padding-top: 15px;
    text-align: center;
    .vfb-control-label:before {
      color: $color_pink;
      content: '* ';
    }
  }
  // Error
  .vfb-has-error {
    .vfb-form-group div {
      position: relative;
      &:after {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
        width: 18px;
        height: 18px;
        border: 1px solid #a94442;
        border-radius: 50%;
        color: #a94442;
        content: '✕';
        font-size: 10px;
      }
    }
    input,
    .vfbp-form-control,
    textarea {
      padding-right: 35px!important;
    }
  }
}
.form,
.vfbp-form .vfb-form-group {
  label {
    @include fs(p);
    display: block;
    margin-bottom: 10px;
    &.required:after {
      color: $color_pink;
      content: '*';
    }
  }
  input,
  .vfbp-form-control {
    padding: 5px 15px;
    width: 100%;
    height: 40px;
    border: 1px solid $color_grey;
    border-radius: 0;
    box-shadow: none;
    font-size: 16px;
    &:valid {
      border-color: #5e861b;
    }
    &:invalid {
      border-color: #a94442;
    }
    &:focus {
      outline: 0;
      border-color: $color_pink;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px lighten($color_pink, 50%);
              box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px lighten($color_pink, 50%);
    }
  }
  textarea {
    border: 1px solid $color_grey;
    border-radius: 0;
    &:focus {
      outline: 0;
      border-color: $color_pink;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px lighten($color_pink, 50%);
              box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px lighten($color_pink, 50%);
    }
  }
}
.mc4wp-form {
  .flex-form {
    display: flex;
  }
  label {
    display: block;
  }
  input {
    padding: 8px 14px;
    width: 100%;
    border: none;
  }
  p {
    margin-bottom: 15px;
  }
  .btn {
    padding: 4px 15px;
    .icon {
      position: relative;
      color: $color_white;
      font-size: 14px;
    }
  }
}
#mc4wp-form-1 {
  margin-top: auto;
}
#vfbp-form-2 {
  .vfb-control-label {
    text-align: left;
  }
  .vfb-fieldType-submit {
    text-align: right;
  }
}


/* Buttons
******************************************/
.btn,
.vfbp-form .btn {
  @include fs(btn);
  display: inline-block;
  padding: 12px 20px;
  border: none;
  border-radius: 0;
  text-decoration: none;
  // Colors
  &.cPink {
    color: $color_pink;
  }
  &.cOrange {
    color: $color_orange;
  }
  &.cWhite {
    color: $color_white;
  }
  // Background
  &.bWhite {
    @include state {
      background: darken($color_white, 10%);
    }
    background: $color_white;
  }
  &.bPink {
    @include state {
      background: lighten($color_pink, 15%);
    }
    background: $color_pink;
  }
}

/* Divers
******************************************/
.scroll-down {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: $margin_s 0;
  width: 100%;
  color: $color_white;
  .icon {
    display: block;
    margin-top: 15px;
    font-size: 10px;
    animation-name: scrollDown;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}
@keyframes scrollDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
}


.multi-column {
  margin-bottom: $margin_s;
  column-count: 1;
  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 30px;
  }
}

/* Site Navigation
******************************************/
.js-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;
}
.site-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px;
  height: 80px;
  background: $color_black;
  .site-logo {
    margin-right: auto;
    img {
      height: 50px;
    }
  }
  button {
    z-index: 100;
  }
}

.site-menu_trigger {
  @include transitionColor($color_orange);
  display: block;
  color: $color_white;
  font-size: 25px;
  @media (min-width: 768px) {
    display: none;
  }
  &-close {
    position: absolute;
    top: 25px;
    right: 32px;
  }
}
.site-menu {
  @include transitionOpacity;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: $color_grey-dark;
  opacity: 0;
  pointer-events: none;
  &.open {
    opacity: 1;
    pointer-events: visible;
  }
}

/* Menus
******************************************/
.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0;
  }
  &.menu_h {
    li {
      display: block;
      @media (min-width: 768px) {
        display: inline-block;
      }
    }
  }
  &.menu_site-navbar {
    display: none;
    margin-right: -20px;
    @media (min-width: 768px) {
      display: block;
    }
    li {
      padding: 10px 25px;
      &.active a:before {
        position: absolute;
        bottom: -5px;
				left: 0;
        display: block;
        width: 100%;
        height: 3px;
        background: $color_orange;
        content: '';
        transition: transform 0.5s cubic-bezier(0, 1, 0.5, 1);
        transform: scaleX(1);
        transform-origin: 0 0;
      }
      a {
        @include state {
          &:before {
            transform: scaleX(1);
            transform-origin: 0 0;
          }
        }
        position: relative;
        color: $color_white;
        text-decoration: none;
        font-size: 18px;
        &:before {
          position: absolute;
          bottom: -5px;
					left: 0;
          display: block;
          width: 100%;
          height: 3px;
          background: $color_orange;
          content: '';
          transition: transform 0.5s cubic-bezier(0, 1, 0.5, 1);
          transform: scaleX(0);
          transform-origin: 100% 0;
        }
      }
    }
  }
  &.menu_site-menu {
    width: 100%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    li {
      a {
        @include transitionColor($color_orange);
        display: block;
        padding: 20px 0;
        color: $color_white;
        text-decoration: none;
        font-size: 28px;
      }
    }
  }

  &.list-icons {
    li {
      @include fs(h3);
      position: relative;
      padding: 15px 50px;
      .fa {
        position: absolute;
        left: 0;
        color: $color_orange;
      }
    }
  }
}

/* Site header
******************************************/
.site-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: calc(100vh - 160px);
  height: 200px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: $color_white;
  text-align: center;
  @media (min-width: 768px) {
    height: 400px;
  }
  .btn {
    padding: 18px 30px;
  }
}

/* Sections
******************************************/
.section {
  padding: $margin_m 0;
  @media (min-width: 768px) {
    padding: $margin_x 0;
  }
  // Image
  .section-image {
    margin-bottom: 30px;
    height: 120px;
    @media (min-width: 768px) {
      height: 200px;
    }
    &._lil {
      height: calc(270px / 2 - 15px);
    }
    &._big {
      height: 330px;
    }
    .bg-container {
      position: relative;
      bottom: 300px;
      min-height: 400px;
    }
  }

  // Colors
  &.bWhite {
    background: $color_white;
    color: $color_grey-dark;
  }
  &.bGrey {
    background: $color_grey-light;
    color: $color_grey-dark;
  }
  &.bOrange {
    background: $color_orange;
    color: $color_white;
    font-weight: bold;
  }
  // Borders
  &.borderTop {
    border-top: 12px solid $color_orange;
  }
  // Margins
  &.pBottom {
    padding-bottom: 310px;
    @media (min-width: 768px) {
      padding-bottom: $margin_x * 2;
    }
  }
  &.no-pBottom {
    padding-bottom: 0;
  }
}

/* Blocks
******************************************/
.block {
  overflow: hidden;
  padding: $margin_m;
  .h1,
  .h2,
  .h3 {
    margin-bottom: 10px;
  }

  &._article {
    position: relative;
    // top: -120px;
    margin-top: -120px;
    margin-bottom: $margin_m;
    @media (min-width: 768px) {
      margin-bottom: $margin_x;
    }
    .h3 {
      color: $color_grey-dark;
    }
  }

  // Colors
  &.bWhite {
    background: $color_white;
    color: $color_black;
  }
  a {
    color: $color_pink;
  }
}


/* Articles
******************************************/
.articles {
  .categories {
    .menu {
      margin: 0;
      margin-bottom: $margin_s;
      li {
        margin-bottom: 0;
        color: $color_pink;
        font-size: 14px;
        a {
          @include fs(p);
          @include state {
            text-decoration: underline;
          }
          display: inline-block;
          padding: 15px 0;
          color: $color_grey-dark;
          text-decoration: none;
        }
      }
    }
    .h3 {
      margin-bottom: 15px;
    }
  }
}

.article-infos {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: $color_grey;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  a {
    color: $color_grey;
    text-decoration: none;
  }
  span {
    display: none;
    margin: 0 7px;
    color: $color_orange;
    @media (min-width: 768px) {
      display: initial;
    }
  }
}

.share {
  padding: $margin_s 0;
  border-top: $color_grey solid 1px;
  border-bottom: $color_grey solid 1px;
  text-align: center;
  a {
    margin-top: 10px;
    padding-left: 0;
    color: $color_pink;
    display: inline-block;
    @media (min-width: 768px) {
      margin-top: 0;
      padding-left: 20px;
    }
  }
}

.pagination {
  .page-numbers {
    display: flex;
    justify-content: center;

    @extend .menu;
    @extend .menu_h;
    li {
      .current {
        padding: 0 15px;
        color: $color_pink;
        text-decoration: underline;
      }
      a {
        @include state {
          color: $color_pink;
          text-decoration: underline;
        }
        padding: 0 15px;
        text-decoration: none;
      }
    }
  }
}

.nav-news {
  display: flex;
  justify-content: space-between;
  margin-top: $margin_m;
  a {
    color: $color_pink;
  }
  .next {
    margin-left: auto;
  }
}



/* Products
******************************************/
.product {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  @media (min-width: 992px) {
    margin-bottom: $margin_m;
  }
  .h3 {
    margin-bottom: 0;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba($color_grey, 0.4);
  }
  .product-price {
    @include fs(p);
    float: right;
    margin-bottom: 25px;
    color: $color_pink;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
  }
  .bg-container {
    margin-bottom: 35px;
    height: 145px;
  }
  .product-content {
    p {
      margin-bottom: 0;
    }
    ul {
      margin-top: 25px;
      margin-bottom: 40px;
      padding-left: 30px;
      li {
        list-style: none;
        &:before {
          position: absolute;
          left: 15px;
          color: $color_pink;
          content: '✔';
        }
      }
    }
  }
  .product-cta {
    margin-top: auto;
    p {
      margin: 15px 0;
      font-weight: bold;
    }
    .link {
      @include state {
        color: lighten($color_pink, 15%);
      }
      color: $color_pink;
      font-weight: normal;
    }
  }
}



/* Modal
******************************************/
.modal-closer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100vw;
  height: 100vh;
}
.modal-container {
  position: relative;
  z-index: 150;
  padding: $margin_m 0;
  background: $color_white;
  color: $color_grey-dark;
}
.modal {
  @include transitionOpacity;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: scroll;
  width: 100vw;
  height: 100vh;
  background: transparentize($color_black, 0.3);
  text-align: left;
  opacity: 0;
  pointer-events: none;
  .container {
    margin-top: $margin_m;
  }
  &.open {
    opacity: 1;
    pointer-events: visible;
  }
  .icon {
    position: absolute;
    top: 25px;
    right: 25px;
  }
  .form {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
  }
}


/* CTA
******************************************/
.cta {
  margin-top: 0;
  @media (min-width: 768px) {
    margin-top: $margin_m + $margin_x;
  }
  .bOrange {
    background-color: $color_orange;
    color: $color_white;
    font-weight: bold;
  }
  .cta_image {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
    height: calc(100% + #{$margin_m});
    @media (min-width: 768px) {
      display: block;
    }
  }
  .cta_block {
    padding: $margin_m * 2 0;
     > .container-fluid {
      width: 100vw;
    }
  }
}


/* Footer
******************************************/
.site-footer {
  position: relative;
  padding: $margin_x 0 $margin_x;
  min-height: 360px;
  background: $color_black;
  color: $color_white;
  font-size: 16px;
  line-height: 25px;
  p {
    font-size: 16px;
    line-height: 25px;
  }

  .toe {
    display: flex;
    flex-direction: column;
    padding-top: $margin_s;
    padding-bottom: $margin_s;
    @media (min-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }
    &.epitact {
      padding-bottom: 0;
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0 ;
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 1px;
        background: $color_orange;
        content: '';
        @media (min-width: 768px) {
          width: 1px;
          height: 100%;
        }
      }
    }
  }

  .site-logo {
    position: absolute;
    top: -135px;
    max-width: 95px;
  }
  .menu {
    &:first-child {
      margin-top: 0;
      padding-bottom: $margin_s;
    }
    li {
      margin-right: 15px;
    }
  }
  .huitbit {
    @include state {
      color: #e54d79;
    }
    text-decoration: none;
  }

  .copyright,
  .newsletter {
    margin-top: auto;
    margin-bottom: 0;
  }

  .extra-links,
  .copyright {
    font-size: 14px;
  }


  .footer-contact {
    margin-top: auto;
  }
}



/* Cookiebar
******************************************/
.cookiebar {
	position: fixed;
	bottom: 1em; left: 1em; right: 1em;
	max-width: 350px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
	transform: translateY( calc(100% + 25px) );
	transition: transform .3s ease 0s;
	z-index: 100;

	&__icon {
		width: 100px;
		position: absolute;
		right: 10px;
		bottom: 0px;
	}

	&__container {
		border-top: 2px solid #272727;
		border-radius: 2px;
		background: #fff;
		padding: 15px 80px 15px 15px;
		font-size: 12px;
		line-height: 1.6;
		overflow-x: hidden;
		position: relative;
	}

	&__accept {
		margin-top: 1em;
	}

	&.active {
		transform: translateY(0);
		transition-delay: 1s;
	}

	a { color: $color_orange; }
	a:before { background: transparent; }
	a:hover,
	a:active,
	a:focus {
		color: #ddcc00;
	}

}
