// Icons
@font-face {
  font-family: icons;
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format('embedded-opentype'), url("../fonts/icons.woff") format('woff'), url("../fonts/icons.ttf") format('truetype'), url("../fonts/icons.svg#icons") format('svg');
  font-weight: normal;
  font-style: normal; }

@mixin icon($content: '') {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    @content; } }

.icon {
  @include icon; }

// Save variable
$icon-ic-avantages: "\EA01";
// Save mixin
@mixin icon--ic-avantages {
  @include icon($icon-ic-avantages) {
    @content; } }
// Expose as class
.icon.-ic-avantages:before {
  content: $icon-ic-avantages; }

// Save variable
$icon-ic-check: "\EA02";
// Save mixin
@mixin icon--ic-check {
  @include icon($icon-ic-check) {
    @content; } }
// Expose as class
.icon.-ic-check:before {
  content: $icon-ic-check; }

// Save variable
$icon-ic-close: "\EA03";
// Save mixin
@mixin icon--ic-close {
  @include icon($icon-ic-close) {
    @content; } }
// Expose as class
.icon.-ic-close:before {
  content: $icon-ic-close; }

// Save variable
$icon-ic-envoyer: "\EA04";
// Save mixin
@mixin icon--ic-envoyer {
  @include icon($icon-ic-envoyer) {
    @content; } }
// Expose as class
.icon.-ic-envoyer:before {
  content: $icon-ic-envoyer; }

// Save variable
$icon-ic-error: "\EA05";
// Save mixin
@mixin icon--ic-error {
  @include icon($icon-ic-error) {
    @content; } }
// Expose as class
.icon.-ic-error:before {
  content: $icon-ic-error; }

// Save variable
$icon-ic-select: "\EA06";
// Save mixin
@mixin icon--ic-select {
  @include icon($icon-ic-select) {
    @content; } }
// Expose as class
.icon.-ic-select:before {
  content: $icon-ic-select; }


