/*****************************************
* SPEC
******************************************/
/* Homepage
******************************************/
.home, .accueil {
  .site-header {
    padding: $margin_s 0;
    height: calc(100vh - 80px);
    @media (min-width: 768px) {
      padding: $margin_x 0;
    }
    .h1-homepage {
      font-size: 30px;
      @media (min-width: 768px) {
        font-size: 40px;
      }
      @media (min-width: 1200px) {
        font-size: 70px;
      }
    }
  }
  .home-section-img {
    height: 350px;
  }
  hr {
    margin-top: 0;
    margin-bottom: $margin_s;
    @media (min-width: 768px) {
      margin-bottom: $margin_x;
    }
  }
  .vulcains {
    .row {
      padding-top: $margin_s;
    }
    .h1 {
      margin-bottom: $margin_m;
    }
    img {
      max-width: 130px;
    }
  }
}

/* About
******************************************/
.section-about_images {
  .row:first-child {
    margin-bottom: $margin_s;
    @media (min-width: 768px) {
      margin-bottom: $margin_x;
    }
  }
  .section-image {
    min-height: 100px;
    height: calc(100% - 30px);
    &._lil {
      height: 160px;
    }
  }
}


/* Page contact
******************************************/
.page-template-contact {
  main a {
    color: $color_pink;
  }
  .map {
    display: block;
    margin-bottom: $margin_s;
    min-height: 235px;
    & ~ a {
      display: block;
      margin-bottom: $margin_s;
      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
  .bloc-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $margin_s;
    padding: 40px;
    min-height: 235px;
    border: 1px solid $color_orange;
    .h3 {
      margin: 0;
    }
  }
}



/* Lang switcher
******************************************/
.wpml {
	&-ls-item {
		width: 50px;
		display: inline-block;
	}
	&-ls-last-item {
		padding-left: 5px!important;
		position: relative;
		&:before {
			content: '';
			display: block;
			height: 15px;
			width: 1px;
			background: $color_orange;
			position: absolute;
			left: -5px;
			top: calc(50% - 7px);
		}
	}
	&-ls-first-item {
		padding-right: 5px!important;
	}
	&-ls-display {
		font-size: 14px;
	}
	&-ls-current-language a {
		color: $color_orange!important;
		pointer-events: none;
	}
}
