/*****************************************
* TMP
******************************************/
/* Page temporaire
******************************************/
.page-template-front-page {
  background: url('../img/default/tmp.jpg');
  color: $color_white;
  .site-logo {
    max-width: 165px;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $margin_m;
  }
  .h1 {
    margin: $margin_m auto;
    text-align: center;
    font-size: 30px;
    @media (min-width: 768px) {
      font-size: 40px;
    }
    @media (min-width: 1200px) {
      margin: $margin_x auto;
      font-size: 50px;
    }
  }
  .mBottom {
    margin-bottom: $margin_x;
    background: #232122;
  }

  .cta-tmp {
    padding: 10px;
    border: 3px solid $color_orange;
    background: #232122;
		@media (min-width: 992px) {
			padding: 60px;
		}
    .h1 {
      margin: 0;
			font-size: 25px;
			@media (min-width: 992px) {
				font-size: 32px;
			}
    }
  }
}
